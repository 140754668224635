<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
				<el-button type="primary" icon="el-icon-download" size="mini" @click="downloadTemplate">下载模板</el-button>
				<el-upload
				  class="upload-demo d-inline-block"
				  ref="upload"
				  action="#"
				  :on-change="importExcel"
				  :on-success="importSuccess"
				  :on-error="importError"
				  :before-upload="beforeImportUpload"
				  :limit="1"
				  :multiple="false"
				  :show-file-list="false"
				  accept=".xls, .xlsx"
				  :auto-upload="false"
				  >
				  <el-button slot="trigger" icon="el-icon-upload2" size="small" type="primary">导入</el-button>
				  </el-upload>
				  <el-button type="primary" icon="el-icon-download" size="mini" @click="downloadExcel">导出</el-button>		
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ (queryForm.page - 1) * queryForm.limit + scope.$index + 1 }}
				  </template>
				</el-table-column>
				<el-table-column label="姓名" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="学号" align="center">
				  <template slot-scope="scope">
					{{ scope.row.code }}
				  </template>
				</el-table-column>
				<el-table-column label="年级" align="center">
				  <template slot-scope="scope">
					{{ scope.row.nianji }}
				  </template>
				</el-table-column>
				<el-table-column label="班级" align="center">
				  <template slot-scope="scope">
					{{ scope.row.banji }}
				  </template>
				</el-table-column>
				<el-table-column label="组别" align="center">
				  <template slot-scope="scope">
					{{ scope.row.group }}
				  </template>
				</el-table-column>
				<el-table-column label="性别" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.gender === 1" size="mini" type="success">男</el-tag>
					<el-tag v-if="scope.row.gender === 2" size="mini" type="danger">女</el-tag>
					<el-tag v-if="scope.row.gender === 3" size="mini" type="info">未知</el-tag>
				  </template>
				</el-table-column>
				<el-table-column label="状态" min-width="60" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
					<el-tag v-if="scope.row.status === 2" size="mini" type="danger">停用</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="160">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.ctime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="编辑" placement="top">
					  <el-button type="success" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="600px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
		    <el-form-item label="姓名" prop="name">
		      <el-input v-model="form.name" placeholder="请输入姓名" type="text" clearable />
		    </el-form-item>
		    <el-form-item label="学号" prop="code">
		      <el-input v-model="form.code" placeholder="请输入学号" type="text" clearable />
		    </el-form-item>
			<el-form-item label="年级" prop="nianji">
			  <el-input v-model="form.nianji" placeholder="请输入年级" type="text" clearable />
			</el-form-item>
<!-- 			<el-form-item label="年级" prop="nianji">
			  <el-select v-model="form.nianji" placeholder="请选择年级" style="width: 100%;">
			    <el-option :value="item.name" :label="item.name" v-for="(item,index) in nianji" :key="item.id"/>
			  </el-select>
			</el-form-item> -->
			<el-form-item label="班级" prop="banji">
			  <el-input v-model="form.banji" placeholder="请输入班级" type="text" clearable />
			</el-form-item>
			<el-form-item label="组别" prop="group">
			  <el-select v-model="form.group" placeholder="请选择组别" style="width: 100%;">
			    <el-option :value="item.name" :label="item.label" v-for="(item,index) in groups" :key="item.id"/>
			  </el-select>
			</el-form-item>
			<el-form-item  label="性别">
			   <el-radio-group v-model="form.gender">
			   	<el-radio :label="1">男</el-radio>
			   	<el-radio :label="2">女</el-radio>
			   	<el-radio :label="3">未知</el-radio>
			   </el-radio-group>
			 </el-form-item>
		    <el-form-item label="身份证号" prop="idcode">
		      <el-input v-model="form.idcode" placeholder="请输入身份证号" type="text" clearable />
		    </el-form-item>
			<el-form-item label="登录密码" prop="password">
			  <el-input v-model="form.password" placeholder="请输入登录密码[为空默认密码:123456]" type="password" clearable />
			  <small class="text-danger font-s" v-if="form.id">为空表示不修改密码</small>
			</el-form-item>
		   <el-form-item  label="状态">
		      <el-radio-group v-model="form.status">
		      	<el-radio :label="1">使用</el-radio>
		      	<el-radio :label="2">停用</el-radio>
		      </el-radio-group>
		    </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	import dayjs from 'dayjs'
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'student',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				form:{
					name:'',
					code:'',
					idcode:'',
					nianji:'',
					banji:'',
					group:'',
					gender:3,
					status:1,
					password:'',
				},
				rules:{
					name: [
					  { required: true, message: '请输入名称', trigger: 'blur' }
					],
					code: [
					  { required: true, message: '请输入学号', trigger: 'blur' }
					],
					nianji: [
					  { required: true, message: '请选择年级', trigger: ['blur', 'change'] }
					],
					group: [
					  { required: true, message: '请选择组别', trigger: ['blur', 'change'] }
					],
				},
				nianji:[],
				groups:[
					{id:1,name:'A',label:'A-语文、数学、英语、物理、化学、生物'},
					{id:2,name:'B',label:'B-语文、数学、英语、物理、化学、地理'},
					{id:3,name:'C',label:'C-语文、数学	、英语、物理	、化学、政治'},
					{id:4,name:'D',label:'D-语文、数学	、英语、物理、生物、地理'},
					{id:5,name:'E',label:'E-语文、数学、英语、物理、生物、政治'},
					{id:6,name:'F',label:'F-语文、数学、英语、物理、地理、政治'},
					{id:7,name:'G',label:'G-语文、数学、英语、历史、化学、生物'},
					{id:8,name:'H',label:'H-语文、数学、英语、历史、化学、地理'},
					{id:9,name:'I',label:'I-语文、数学、英语、历史、化学、政治'},
					{id:10,name:'J',label:'J-语文、数学、英语、历史、生物、地理'},
					{id:11,name:'K',label:'K-语文、数学、英语、历史、生物、政治'},
					{id:12,name:'L',label:'L-语文、数学、英语、历史、地理、政治'},
					{id:13,name:'H',label:'H-语文、数学、英语、历史、化学、地理'},
					{id:14,name:'N',label:'N-语文、数学、英语、物理、化学、生物、政治、历史、地理'},
				],
			}
		},
		mounted() {
			this.getNianji()
		},
		methods:{
			reset() {
			  this.form = {
			    name:'',
			    code:'',
			    idcode:'',
			    nianji:'',
			    banji:'',
			    group:'',
			    gender:3,
			    status:1,
				password:'',
			  }
			  this.resetForm('form')
			},
			downloadTemplate(){
				this.axios.get('/manage/student/downloadTemplate',{
					responseType: 'arraybuffer',
				}).then(res=>{
					  const aLink = document.createElement("a");
					  let blob = new Blob([res], { type: "application/vnd.ms-excel" });
					  aLink.href = URL.createObjectURL(blob);
					  aLink.download = '学生导入模板.xlsx';
					  aLink.click();
					  document.body.appendChild(aLink);
				})
			},
			importExcel(e){
				//获取用户选择的文件
				const file = e.raw
				let formdata = new FormData()
				formdata.append('file', file)
				this.axios.post('/manage/student/importExport',formdata).then(res=>{
					if(res.status){
						this.$message.success('导入成功')
						this.getList()
					}else{
						this.$message.success('导入失败')
					}
					this.$refs.upload.clearFiles()
				})
			},
			beforeImportUpload(file){
				  let fileArr = file.name.split('.')
				  let suffix = fileArr[fileArr.length - 1]
				  //只能导入.xls和.xlsx文件
				  if (!/(xls|xlsx)/i.test(suffix)) {
					this.$message('文件格式不正确')
					return false
				  }
				  return true
			},
			downloadExcel(){
				this.axios.get('/manage/student/downloadExcel',{
					responseType: 'arraybuffer',
					params:this.queryForm
				}).then(res=>{
					console.log(res)
					  let dayjsTime = dayjs(`${new Date()}`).format('YYYY-MM-DD')
					  const aLink = document.createElement("a");
					  let blob = new Blob([res], { type: "application/vnd.ms-excel" });
					  aLink.href = URL.createObjectURL(blob);
					  aLink.download = '学生列表_'+dayjsTime+'.xlsx';
					  aLink.click();
					  document.body.appendChild(aLink);
				})
			},
		}
	}
</script>

<style>
</style>